import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn';
/**
 * # 取出可以為 Charting 選擇策略的函式
 *
 * ### Chart2 OK
 *
 * ### Chart4 OK
 */
export const useChartingSelectStrategyFn = (charting) => useChartingFn(charting, {
    chart2: chart2 => (config) => {
        const chart = chart2.getState();
        chart.widget?.setSymbol(config.symbol || chart.symbol, config.interval, () => {
            //
        });
        return chart.changeIndicators(config.indicators)?.then(() => {
            config.panesRatio && chart.changePaneRatio(config.panesRatio);
        });
    },
    chart4: chart4 => (config) => {
        chart4.setStrategy(config);
        return chart4.updateFromStrategyState();
    },
});
