import { css } from '@emotion/react';
import React, { memo, useState } from 'react';
import { fill, flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { proxy, useSnapshot } from 'valtio';
import { NotifyModule } from '~/modules/SDK/NotifyModule/NotifyModule';
import dayAPI from '~/utils/dayAPI';
import Snackbar from '@mui/material/Snackbar';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { MessageModal } from '~/modules/investment-consultant/signalNotify/MessageModal';
import useMedia from '~/hooks/useMedia';
import { useThemeStore } from '~/components/theme/useThemeStore';
const notify1 = new NotifyModule({ name: 'notify_morerich' });
const notifyMessageStore = proxy({
    index: 0,
});
/** 訊息時間切換按鈕 */
export const MessageTimeButton = memo(function TimeButtom(props) {
    const notifyMessageState = useSnapshot(notifyMessageStore);
    const onState = props.messageNumber === notifyMessageState.index;
    const theme = useThemeStore(t => t.theme);
    return (<classes.timeButton selected={onState} className={theme} onClick={() => (notifyMessageStore.index = props.messageNumber)}>
      {props.timeText}
    </classes.timeButton>);
});
export const ManualSignal = memo(function ManualSignal_(props) {
    const { isPc } = useMedia();
    const modal = props.modal ?? false;
    const theme = useThemeStore(t => t.theme);
    notify1.store.useLogs({ agent: props.agent, limit: props.limit }, { pollingInterval: 5000 });
    const state1 = useSnapshot(notify1.store);
    /** 過濾訊息:只顯示當日訊息 */
    const messageContext = state1.logs.filter(datum => dayAPI(datum.datetime).format('MM-DD') === dayAPI().format('MM-DD'));
    /** 狀態:目前選取陣列中第幾則訊息 limit=5 0~4 */
    const notifyMessageStateNumber = useSnapshot(notifyMessageStore);
    /** HH:mm 時間格式 */
    const notifyTime = state1.logs.map(datum => dayAPI(datum.datetime).format('HH:mm'));
    /** 訊息內容 */
    const notifyMessageContent = messageContext.map(datum => datum.content);
    const [snackbarState, setSnackbarState] = useState(false);
    const [lastTime, setLastTime] = useState(notifyTime[0]);
    //一開始載入網頁時不跳出mui通知 只在最新發訊時間變化時跳出通知
    if (lastTime !== notifyTime[0]) {
        setLastTime(notifyTime[0]);
        //lastTime初始為undefined
        lastTime !== undefined && setSnackbarState(true);
        notifyMessageStore.index = 0;
    }
    return (<classes.Root css={baseStyled}>
        <classes.cntainer className={theme}>
          <classes.titleContent className={theme}>時間按鈕</classes.titleContent>
          <classes.titleContent className={theme}>訊息內容</classes.titleContent>
          <classes.timeButtonContainer className={theme}>
            {messageContext.map((datum, index) => {
            return (<MessageTimeButton key={index} messageNumber={index} timeText={dayAPI(datum.datetime).format('HH:mm')}/>);
        })}
          </classes.timeButtonContainer>
          <classes.messageContainer className={theme}>
            {messageContext.length === 0
            ? '今天還沒有訊息'
            : notifyMessageContent[notifyMessageStateNumber.index]}

            {modal === true && isPc && (<MessageModal time={messageContext.map(s => dayAPI(s.datetime).format('YYYY-MM-DD HH:mm'))[notifyMessageStateNumber.index]} text={messageContext.length === 0
                ? '今天還沒有訊息'
                : notifyMessageContent[notifyMessageStateNumber.index]}>
                操作提示
              </MessageModal>)}
          </classes.messageContainer>

          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbarState} autoHideDuration={6000} onClose={() => setSnackbarState(false)} key={'bottom' + 'left'}>
            <Alert severity='info'>{'時間' + notifyTime[0] + ' 您有新的訊息'}</Alert>
          </Snackbar>
        </classes.cntainer>
      </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill}
  `,
    cntainer: styled.div `
    ${pureGrid};
    width: 100%;
    height: 100%;
    grid-template-rows: 30px calc(100% - 30px);
    grid-template-columns: 80px calc(100% - 80px);
    font-size: 15px;
  `,
    timeButtonContainer: styled.div `
    ${scrollbar2Css};
    ${flex.v.crossCenter};

    grid-column: 1 / 2;
    grid-row: 2;
  `,
    messageContainer: styled.div `
    ${scrollbar2Css};
    grid-column: 2 / 2;
    grid-row: 2;
  `,
    titleContent: styled.div `
    width: 100%;
    grid-row: 1;
    ${flex.h.allCenter};
  `,
    timeButton: styled.div `
    ${flex.h.allCenter};
    width: 70px;
    height: 30px;
    border-radius: 5px;
    margin: 2.5px 0px;
    cursor: pointer;
    &.dark {
      border: 1px solid ${props => (props.selected === true ? '#00ffff' : '#2a2d2f')};
    }
    &.light {
      border: 1px solid ${props => (props.selected === true ? '#313030' : '#909090')};
    }
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.cntainer} {
  }
  ${classes.timeButtonContainer} {
    &.dark {
      border-right: 1px solid #555555;
    }
    &.light {
      border-right: 1px solid #aaaaaa;
    }
  }
  ${classes.messageContainer} {
  }
  ${classes.titleContent} {
    &.dark {
      background-color: #444444;
      color: #fafafa;
    }
    &.light {
      background-color: #cccccc;
      color: #252525;
    }
  }
  ${classes.timeButton} {
    &.dark {
      background-color: #2a2d2f;
      color: #fafafa;
      &:hover {
        background-color: #474d57;
      }
    }
    &.light {
      background-color: #cccccc;
      color: #252525;
      &:hover {
        background-color: #dddddd;
      }
    }
  }
`;
export default {
    classes,
};
