import axios from 'axios-0-27-2';
import { useEffect } from 'react';
import { useAsyncFn, useInterval } from 'react-use';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
/**
 * @example
 *   //
 *   const notify1 = new NotifyModule({ name: 'notify_futuresai' })
 *   const notify2 = new NotifyModule({ name: 'notify_good178' })
 *
 *   export default function NextPage() {
 *     const logsState1 = notify1.store.useLogs(
 *       { agent: 'futuresai', limit: 5 },
 *       { pollingInterval: 3333 },
 *     )
 *
 *     const logsState2 = notify2.store.useLogs(
 *       { agent: 'good178', limit: 5 },
 *       { pollingInterval: 5555 },
 *     )
 *
 *     const state1 = useSnapshot(notify1.store)
 *     const state2 = useSnapshot(notify2.store)
 *
 *     return (
 *       <WithSidebar>
 *         <h2>{`agent: 'futuresai',`}</h2>
 *
 *         {logsState1.loading ? <FaSpinner /> : <FaCheck />}
 *
 *         {state1.logs.map(item => {
 *           return (
 *             <Fragment key={item.id}>
 *               <ButtonGroup fullWidth>
 *                 <AppTooltip
 *                   title={item.datetime}
 *                   placement='right'
 *                 >
 *                   <Button>{dayAPI(item.datetime).fromNow()}</Button>
 *                 </AppTooltip>
 *                 <Button>{item.agentName}</Button>
 *                 <Button>{item.content}</Button>
 *               </ButtonGroup>
 *             </Fragment>
 *           )
 *         })}
 *
 *         <h2>{`agent: 'good178',`}</h2>
 *
 *         {logsState2.loading ? <FaSpinner /> : <FaCheck />}
 *
 *         {state2.logs.map(item => {
 *           return (
 *             <Fragment key={item.id}>
 *               <ButtonGroup fullWidth>
 *                 <AppTooltip
 *                   title={item.datetime}
 *                   placement='right'
 *                 >
 *                   <Button>{dayAPI(item.datetime).fromNow()}</Button>
 *                 </AppTooltip>
 *                 <Button>{item.agentName}</Button>
 *                 <Button>{item.content}</Button>
 *               </ButtonGroup>
 *             </Fragment>
 *           )
 *         })}
 *       </WithSidebar>
 *     )
 *   }
 */
export class NotifyModule {
    store;
    constructor(config) {
        this.store = createProxy();
        devtools(this.store, config?.name);
    }
}
const createProxy = () => proxy({
    logs: [],
    useLogs(params, options) {
        const jwt = useMeStore(state => state.meFirebaseState?.jwt);
        const [state, fetch] = useAsyncFn(() => {
            if (!jwt)
                return Promise.reject('未登入');
            return axios
                .get('https://member-auth-v2.run.futures-ai.com/api/notify/bulletin', {
                params,
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then(res => {
                this.logs = res.data;
            });
        }, [jwt]);
        useEffect(() => {
            if (!jwt)
                return;
            fetch();
        }, [fetch, jwt]);
        useInterval(() => {
            fetch();
        }, options?.pollingInterval ? options.pollingInterval : 60000);
        return state;
    },
});
