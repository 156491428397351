import { css } from '@emotion/react';
import { MenuItem, menuClasses, Select, selectClasses } from '@mui/material';
import { memo, useState } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { find } from 'lodash';
import { useChartingSelectStrategyFn } from '~/modules/SDK/chart4/hooks/useChartingSelectStrategyFn';
/**
 * 測試中
 *
 * Richadam
 *
 * Win168
 */
export const IndicatorsSelect = memo(function IndicatorsSelect(props) {
    const [displayName, displayNameSet] = useState(props.strategiesConfig[0].displayName);
    const changeIndicators = useChartingSelectStrategyFn(props.charting);
    return (<span id={props.id} className={`${props.className} ${IndicatorsSelectClasses.root}`} css={css `
        #__next & {
          ${flex.h.allCenter};
          box-sizing: border-box;
          padding: 4px;
          width: 100%;
        }
      `}>
      <Select fullWidth size='small' value={displayName} onChange={event => {
            const eventValue = event.target.value;
            const selectedIndicatorsConfig = find(props.strategiesConfig, item => {
                return item.displayName === eventValue;
            });
            displayNameSet(eventValue);
            if (selectedIndicatorsConfig) {
                changeIndicators(selectedIndicatorsConfig);
            }
            else {
                console.error(`找不到 useChart 或 indicators`);
            }
        }}>
        {props.strategiesConfig?.map((value, index) => {
            return (<MenuItem key={index} value={value.displayName}>
              {value.displayName}
            </MenuItem>);
        })}
      </Select>
    </span>);
});
export const IndicatorsSelectClasses = {
    root: `${IndicatorsSelect.name}-root`,
    muiSelect: selectClasses,
    muiMenuItem: menuClasses,
};
